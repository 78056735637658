import "../src/styles/globals.scss";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Flip } from "gsap/dist/Flip";
import { GSDevTools } from "@utils/gsap/GSDevTools";
import { DrawSVGPlugin } from "@utils/gsap/DrawSVGPlugin";
import { SplitText } from "@utils/gsap/SplitText";

if (typeof window !== "undefined") {
  gsap.registerPlugin(
    Flip,
    ScrollTrigger,
    GSDevTools,
    DrawSVGPlugin,
    SplitText
  );
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <AnimatePresence exitBeforeEnter>
      <Component {...pageProps} key={router.asPath} />
    </AnimatePresence>
  );
}

export default MyApp;
